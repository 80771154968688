<template>
    <v-tour name="HarpiaTour" :steps="TourSteps" :callbacks="TourCallbacks"></v-tour>
</template>
<style lang="scss">
</style>
<script>
export default {
    name: 'TheTour',
    props: {
        TourName: String
    },
    data() {
        return {
            TourSteps: [{
                    target: '[data-v-step="0"]',
                    content: this.$i18n.t('tour.main'),
                    params: { placement: 'bottom' }
                },
                {
                    target: '[data-v-step="1"]',
                    content: this.$i18n.t('tour.navbar'),
                    params: { placement: 'bottom' }
                },
                {
                    target: '[data-v-step="2"]',
                    content: this.$i18n.t('tour.operation'),
                    params: { placement: 'bottom' }
                },
                {
                    target: '[data-v-step="3"]',
                    content: this.$i18n.t('tour.transmission'),
                    params: { placement: 'auto' }
                },
                {
                    target: '[data-v-step="4"]',
                    content: this.$i18n.t('tour.options'),
                    params: { placement: 'auto' }
                },
            ],
            TourCallbacks: {
                onSkip: this.TourFinishedHandle,
                onFinish: this.TourFinishedHandle
            }
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        }
    },
    watch: {
        GetCurrentUser: {
            handler(user) {
                if (user.permissions.georefview) {
                    this.$store.dispatch('database/GetFromDatabase', {
                        path: 'users/' + user.uid + '/seenTutorial',
                        returnSnapshotVal: true
                    }).then(seen => {
                        // let userFirstName = ''
                        // user.name ? userFirstName = user.name.split(' ')[0] : userFirstName = 'usuário'
                        // this.TourSteps[0].content = 'Bem vindo ao Harpia, ' + userFirstName + '!<br> Este é um breve guia para auxiliar sua navegação. Clique em próximo para continuar!'
                        if (!seen) this.$tours['HarpiaTour'].start()
                    })
                }
            }
        }
    },
    methods: {
        TourFinishedHandle() {
            this.$store.dispatch('database/SetOnDatabase', {
                path: 'users/' + this.GetCurrentUser.uid + '/seenTutorial',
                object: true
            })
        }
    },
    mounted() {
        this.$tours.HarpiaTour.options.labels = {
            buttonSkip: this.$i18n.t('tour.labels.buttonSkip'),
            buttonPrevious: this.$i18n.t('tour.labels.buttonPrevious'),
            buttonNext: this.$i18n.t('tour.labels.buttonNext'),
            buttonStop: this.$i18n.t('tour.labels.buttonStop')
        }
    }
}
</script>