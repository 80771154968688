<template>
    <iframe :src="VideoId" allow="autoplay" frameborder="0" allowfullscreen :class="VideoSize"></iframe>
</template>
<style lang="scss" scoped>
.is-halfscreen {
    height: 100%;
    width: 100%;
}
</style>
<script>
export default {
    name: 'BaseLivestream',
    props: {
        VideoId: String,
        VideoSize: String
    }
}
</script>