<template>
    <section class="hero is-light is-fullheight-with-navbar">
        <div class="hero-body">
            <div class="container">
                <div class="has-text-centered has-text-primary">
                    <figure class="image is-128x128 is-inline-block">
                        <img :src="GetStopReportDescription.icon" />
                    </figure>
                </div>
                <br>
                <h1 class="has-text-custom-font title has-text-grey-dark">
                    {{ GetStopReportDescription.message }}
                </h1>
            </div>
        </div>
    </section>
</template>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.has-text-custom-font {
    font-family: 'Montserrat', sans-serif;
    color: #303030 !important;
}

p {
    font-size: 5vh;
}

.is-centered-image {
    justify-content: center;
}

.hero.is-light {
    background-color: #DBDBDB;
}

.is-fullscreen {
    position: fixed;
    top: 25;
    bottom: 50;
    left: 0;
    padding-bottom: 4px;
    min-width: 100%;
    min-height: 50%;
    width: auto;
    height: calc(100vh - 3.25rem);
}
</style>
<script>
export default {
    name: 'BaseStopReport',
    computed: {
        GetStopReportDescription() {
            let stopReportMessage = {}
            switch (this.$store.getters['database/GetStopReport'].description) {
                case 'meteorology':
                    stopReportMessage = { message: 'Operação aguardando melhora da condição climática.', icon: require('@/assets/stop-report-gif.gif') }
                    break
                case 'maintenance':
                    stopReportMessage = { message: 'Operação aguardando manutenção de equipamentos.', icon: require('@/assets/stop-report-gif.gif') }
                    break
                case 'non-working-hours':
                    stopReportMessage = { message: 'Operação indisponível neste momento.', icon: require('@/assets/stop-report-gif.gif') }
                    break
                case 'trainig':
                    stopReportMessage = { message: 'Operação aguardando treinamento de operadores.', icon: require('@/assets/stop-report-gif.gif') }
                    break
                case 'misc-external':
                    stopReportMessage = { message: 'Operação aguardando resolução de fatores externos.', icon: require('@/assets/stop-report-gif.gif') }
                    break
                case 'misc-internal':
                    stopReportMessage = { message: 'Operação aguardando resolução de fatores internos.', icon: require('@/assets/stop-report-gif.gif') }
                    break
                case 'no-livestream':
                    stopReportMessage = { message: 'Operação acontecendo normalmente. Transmissão ao vivo aguardando resolução de fatores externos.', icon: require('@/assets/stop-report-gif.gif') }
                    break
                case 'stop-report-mock':
                    stopReportMessage = { message: this.$store.getters['database/GetStopReport'].detail, icon: require('@/assets/stop-report-gif.gif') }
                    break
            }
            return stopReportMessage
        }
    }
}
</script>