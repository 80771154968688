<template>
    <div class="columns is-multiline">
        <div class="column is-full">
            <b-table :data="ReturnTableData" :columns="columns" :narrowed="true" :bordered="true"></b-table>
        </div>
        <div class="column is-full" v-if="GetCurrentUser.role !== 'visualizer'">
            <div class="buttons has-text-centered">
                <b-button type="is-success" @click="MarkAsSeen" class="is-fullwidth" v-if="device.messages.message.isInformationMessage && !device.messages.message.seen">
                    <span>Marcar como visualizado</span>
                </b-button>
                <b-button type="is-info" @click="ConfirmTrueAlarm" class="is-fullwidth" v-if="device.messages.message['payload_fields'].messageType === 'ALERT' && !device.messages.message.seen">
                    <span>Marcar alarme verdadeiro</span>
                </b-button>
                <b-button type="is-danger" @click="ConfirmFalseAlarm" class="is-fullwidth" v-if="device.messages.message['payload_fields'].messageType === 'ALERT' && !device.messages.message.seen">
                    <span>Marcar alarme falso</span>
                </b-button>
            </div>
        </div>
        <div class="column is-full">
            <div class="has-text-centered">
                <span class="has-text-weight-bold">STATUS: </span>
                <span v-if="device.messages.message.seen && device.messages.message.isFalseAlarm && !device.messages.message.isInformationMessage">
                    <span class="has-text-info has-text-weight-bold">{{ $t('sensors.legend.falseAlarm') }}</span> {{ $t('sensors.legend.confirmed') }}
                </span>
                <span v-else-if="device.messages.message.seen && !device.messages.message.isFalseAlarm && !device.messages.message.isInformationMessage">
                    <span class="has-text-danger has-text-weight-bold">{{ $t('sensors.legend.trueAlarm') }}</span> {{ $t('sensors.legend.confirmed') }}
                </span>
                <span v-else-if="!device.messages.message.seen && !device.messages.message.isInformationMessage">
                    <span class="has-text-danger has-text-weight-bold">{{ $t('sensors.legend.notSeen') }}</span> {{ $t('sensors.legend.operator') }}
                </span>
                <span v-else-if="device.messages.message.seen && device.messages.message.isInformationMessage">
                    <span class="has-text-info has-text-weight-bold">{{ $t('sensors.legend.seen') }}</span> {{ $t('sensors.legend.operator') }}
                </span>
                <span v-else-if="!device.messages.message.seen && device.messages.message.isInformationMessage">
                    <span class="has-text-danger has-text-weight-bold">{{ $t('sensors.legend.notSeen') }}</span> {{ $t('sensors.legend.operator') }}
                </span>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>
export default {
    name: 'MapSensorTooltip',
    data() {
        return {
            columns: [{ //Columns used on the table to show last message information.
                    field: 'id',
                    label: this.$i18n.t('sensors.table.id'),
                    width: '20',
                    numeric: true
                },
                {
                    field: 'time',
                    label: this.$i18n.t('sensors.table.time'),
                },
                {
                    field: 'elapsed',
                    label: this.$i18n.t('sensors.table.elapsed'),
                },
                {
                    field: 'message',
                    label: this.$i18n.t('sensors.table.message'),
                },
                {
                    field: 'battery',
                    label: this.$i18n.t('sensors.table.battery'),
                },
            ],
        }
    },
    props: {
        device: Object, //Props containing entire object from the last message received.
        mac: String, //Props containing mac address from the sensor.
        time: String //Props containing timestamp from the sensor.
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        ReturnTableData() { //Computed property to return data to populate the table. Done this way because some field of the table need logic.
            return [{
                'id': this.device.boxId,
                'time': new Date(this.device.messages.message.metadata.time).toLocaleString('pt-BR'),
                'elapsed': this.GetElapsedHours(new Date(this.device.messages.message.metadata.time)) + this.$i18n.t('sensors.table.elapsedHoursAgo'),
                'message': this.device.messages.message['payload_fields'].messageType,
                'battery': this.device.messages.message['payload_fields'].battery
            }]
        },
        ReturnDate() { //Computed property to return formatted date.
            let date = new Date(this.time)
            date = date.toLocaleDateString('pt-BR')
            date = date.split('/').join('-')
            return date
        }
    },
    methods: {
        GetElapsedHours(AlertDate) { //Method to get how many hours passed since a given timestamp.
            let currentDate = new Date()
            let hourDifference = (AlertDate.getTime() - currentDate.getTime()) / 1000
            hourDifference /= (60 * 60)
            return Math.abs(Math.round(hourDifference))
        },
        MarkAsSeen() { //Method to mark this sensor marker as seen (green).
            this.$store.dispatch('database/MarkGatewayMessageSeen', { mac: this.mac, status: { seen: true }, device: this.device, date: this.ReturnDate })
        },
        ConfirmFalseAlarm() { //Method to mark this sensor marker as false alarm.
            this.$buefy.dialog.confirm({
                title: 'Marcar alarme falso',
                message: 'Tem certeza que deseja marcar este alarme como falso?',
                confirmText: 'Confirmar',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () =>
                    this.$store.dispatch('database/MarkGatewayMessageSeen', { mac: this.mac, status: { seen: true, isFalseAlarm: true }, device: this.device, date: this.ReturnDate })
            })
        },
        ConfirmTrueAlarm() { //Method to mark this sensor marker as true alarm.
            this.$buefy.dialog.confirm({
                title: 'Marcar alarme verdadeiro',
                message: 'Tem certeza que deseja marcar este alarme como verdadeiro?',
                confirmText: 'Confirmar',
                cancelText: 'Cancelar',
                type: 'is-info',
                hasIcon: true,
                onConfirm: () =>
                    this.$store.dispatch('database/MarkGatewayMessageSeen', { mac: this.mac, status: { seen: true, isFalseAlarm: false }, device: this.device, date: this.ReturnDate })
            })
        },
    },
}
</script>