<template>
    <div class="is-100-percent-height">
        <l-map id="map" ref="map" position="is-bottom" class="map" :zoom="MapStartingZoom" :max-zoom="MapMaxZoom" :center="MapStartingCenter" :options="{zoomControl: false}" :key="ForceReRender">
            <!-- <b-notification class="has-high-z-index" type="is-info" :closable="false" v-if="this.$store.getters['database/GetCameraTourStatus']['.value']" container="#map" has-icon>
                <p class="is-size-5">A câmera está percorrendo pontos pré-definidos em sua ronda.</p>
            </b-notification> -->
            <!-- <b-dropdown class="is-hidden is-fixed-button" aria-role="list">
                <b-button class="is-circle-button is-primary" slot="trigger">
                    <b-icon icon="cog" size="is-small"></b-icon>
                </b-button>
                <b-dropdown-item v-if="PolygonCount < PolygonLimit" @click="CallPolygonDrawing">
                    <b-icon icon="draw-polygon"></b-icon>
                    Desenhar polígono <span>{{ PolygonCount }}/{{ PolygonLimit }}</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="PolygonCount > 0" @click="CallPolygonDeletion">
                    <b-icon icon="trash"></b-icon>
                    Excluir polígonos
                </b-dropdown-item>
                <b-dropdown-item @click="ChangePolygonInclude">
                    <b-icon icon="home"></b-icon>
                    Alertas no(s) polígono(s):
                    <span v-if="PolygonInclude" class="has-text-weight-semibold has-text-success">permitido </span>
                    <span v-else class="has-text-weight-semibold has-text-danger">não permitido </span>
                    <b-icon icon="exchange-alt" size="is-small"></b-icon>
                </b-dropdown-item>
                <b-dropdown-item v-if="IsConfirmPolygonButtonShowing" @click="ConfirmDrawnPolygon">
                    <b-icon icon="check-square" type="is-primary"></b-icon>
                    Confirmar polígonos
                </b-dropdown-item>
            </b-dropdown> -->
            <l-tile-layer v-for="TileProvider in MapTileProviders" :key="TileProvider.id" :attribution="TileProvider.attribution" :url="TileProvider.url" layer-type="base" />
            <l-geo-json :geojson="CurentGeoJSON" v-if="CurentGeoJSON"></l-geo-json>
            <div v-if="GetCurrentUser.activeFlag.toLowerCase() === 'altave'">
                <l-marker v-for="device in GetGatewayMessages" :key="device.messages.message['payload_fields'].macAddress" :lat-lng="[device.coordinates.latitude, device.coordinates.longitude]" :icon="SetMarkerColor(device.messages.message['payload_fields'].messageType, device.messages.message.seen)">
                    <l-popup>
                        <MapSensorTooltip :device="device" :mac="device.messages.message['payload_fields'].macAddress" :time="device.messages.message.metadata.time" />
                    </l-popup>
                </l-marker>
            </div>
        </l-map>
    </div>
</template>
<style lang="scss" scoped>
.map {
    z-index: 0;
}

.is-fixed-button {
    z-index: 998;
    top: 4.6vw;
    padding-top: 2vw;
    left: 64vw;
    position: fixed;
}

.is-circle-button {
    border-radius: 100px;
}

.is-100-percent-height {
    height: 100%;
}

.has-high-z-index {
    z-index: 999;
}
</style>
<script>
import MapSensorTooltip from '@/components/georef/MapSensorTooltip.vue'
import { LMap, LTileLayer, LGeoJson, LMarker, LPopup } from 'vue2-leaflet'
// import { GeorefDrawToolbarMixin } from '@/mixins/GeorefDrawToolbarMixin.js'
// import { MapGeoTiff } from '@/mixins/MapGeoTiff.js'
import 'leaflet-extra-markers/dist/js/leaflet.extra-markers.min.js'
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css'
export default {
    name: 'MapGeoref',
    components: {
        LMap,
        LTileLayer,
        LGeoJson,
        LMarker,
        LPopup,
        MapSensorTooltip
    },
    props: {
        ForceReRender: Number
    },
    // mixins: [ GeorefDrawToolbarMixin MapGeoTiff],
    data() {
        return {
            CurentGeoJSON: null,
            CanShowGeoJSON: false,
            MapStartingZoom: 14,
            MapMaxZoom: 17,
            MapStartingCenter: [-23, -45],
            MapIsCenterSet: false,
            MapTileProviders: [{
                    id: 1,
                    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                },
                {
                    id: 2,
                    attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-hybrid/{z}/{x}/{y}{r}.png',
                    ext: 'png'
                }
            ]
        }
    },
    computed: {
        GetCurrentGeoJSON() {
            return this.$store.getters['database/GetCurrentGeoJSON']
        },
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        GetGatewayMessages() {
            return this.$store.getters['database/GetGatewayMessages']
        },
        GetGatewayLastMessage() {
            return this.$store.getters['database/GetGatewayLastMessage']
        },
        GetCameraTourStatus() {
            return this.$store.getters['database/GetCameraTourStatus']
        }
    },
    watch: {
        GetCurrentGeoJSON: {
            handler(currentGeoJSON) {
                if (!currentGeoJSON) return
                this.ShowGeoJSON(currentGeoJSON)
            }
        },
        GetCurrentUser: {
            handler() {
                this.$store.dispatch('database/unbindCurrentGeoJSON')
                this.$store.dispatch('database/bindCurrentGeoJSON', this.GetCurrentUser.activeFlag)
                this.MapIsCenterSet = false
            },
            deep: true
        },
        GetGatewayLastMessage: {
            handler(message) {
                if (!message[0]) return
                if (message[0].payload_fields.messageType === 'ALERT' && message[0].seen === false) { //If message is equal to alert and wasn't seen, play sound.
                    // this.PlayAlertSound('http://soundbible.com/mp3/sms-alert-3-daniel_simon.mp3')
                    this.$buefy.snackbar.open({
                        duration: 5000,
                        message: 'Há novo alerta de movimentação em um dos sensores.',
                        type: 'is-danger',
                        position: 'is-top-right',
                        actionText: 'Confirmar',
                        container: '#map',
                        queue: false,
                    })
                }
            }
        },
    },
    methods: {
        ShowGeoJSON(currentGeoJSON) {
            let coordinates = currentGeoJSON.features[1].geometry.coordinates
            if (!this.MapIsCenterSet) {
                this.MapStartingCenter = [coordinates[1], coordinates[0]]
                this.MapIsCenterSet = true
            }
            this.CurentGeoJSON = currentGeoJSON
            this.CanShowGeoJSON = true
        },
        PlayAlertSound(sound) { //Method to play an alert sound.
            if (sound) {
                let audio = new Audio(sound);
                audio.play();
            }
        },
        SetMarkerColor(message, SeenStatus) { //Method to change sensor markers colors according to their type.
            let color = ''
            if (!SeenStatus) { //If the sensor marker message wasn't seen it assumes one of the following colors.
                switch (message) {
                    case 'DEFAULT':
                        color = 'orange'
                        break
                    case 'STARTUP':
                        color = 'blue'
                        break
                    case 'ALERT':
                        color = 'red'
                        break
                    default:
                        color = 'black'
                }
            } else { //If the sensor marker message was seen it turns to green.
                color = 'green'
            }
            return window.L.ExtraMarkers.icon({ //Returns Leaflet marker object with the proper color.
                icon: 'fa-wifi',
                markerColor: color,
                shape: 'circle',
                prefix: 'fa'
            })
        }
    },
    created() {
        if (this.GetCurrentUser.activeFlag.toLowerCase() === 'altave') {
            this.$store.dispatch('database/bindGatewayMessages')
            this.$store.dispatch('database/bindGatewayLastMessage')
        }
        let geoJSON = this.$store.getters['database/GetCurrentGeoJSON']
        if (geoJSON) this.ShowGeoJSON(geoJSON)
    }
}
</script>