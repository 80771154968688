<template>
    <div>
        <TheTour TourName="HarpiaTour" />
        <TheNavbar data-v-step="1" />
        <div v-if="GetStopReport">
            <BaseStopReport />
        </div>
        <div v-else>
            <fab v-if="GetCurrentUser.permissions.georefview" :enable-rotation="false" data-v-step="4" :actions="FabActions" :icon-size="'medium'" :main-tooltip="this.$i18n.t('fab.mainTooltip')" :main-icon="'satellite'" :bg-color="FabBackgroundColor" @TurnGeorefOnOff="ShowHideGeoref">
            </fab>
            <div class="columns is-mobile is-gapless has-background-black is-fullscreen is-relative">
                <b-loading :is-full-page="false" v-model="IsVideoLoading" :can-cancel="true"></b-loading>
                <div class="column" v-bind:class="{ 'is-three-fifths': IsGeorefActive }" data-v-step="3">
                    <BaseLivestream :VideoId="CurentLivestream" :VideoSize="'is-halfscreen'" v-if="IsStreaming && LivestreamProvider !== 'kinesis'" />
                    <div class="is-parent-full-width-height" v-else-if="IsStreaming && LivestreamProvider === 'kinesis'">
                        <BasePlayer :options="BasePlayerOptions" :videoSource="BasePlayerSource" />
                    </div>
                </div>
                <div class="column" v-if="IsGeorefActive">
                    <MapGeoref :ForceReRender="ForceReRender" />
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
html {
    background-color: #0A0A0A
}

.vertical-horizontal-center {
    height: 100vh;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.column.has-vertically-aligned-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.is-fullscreen {
    position: fixed;
    top: 25;
    bottom: 50;
    left: 0;
    padding-bottom: 4px;
    min-width: 100%;
    min-height: 50%;
    width: auto;
    height: calc(99.6vh - 3.25rem);
}

.is-parent-full-width-height {
    width: 100%;
    height: 100%;
}

@media (min-device-width : 640px) and (max-device-width : 812px) and (orientation: landscape) {
    .is-fullscreen {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 87%;
        width: auto;
        height: 87vh;
    }
}
</style>
<script>
import firebase from '../../firebase.config.js'
import TheNavbar from '@/components/global/TheNavbar.vue'
import TheTour from '@/components/home/TheTour.vue'
import BaseLivestream from '@/components/home/BaseLivestream.vue'
import BasePlayer from '@/components/home/BasePlayer.vue'
import BaseStopReport from '@/components/home/BaseStopReport.vue'
import MapGeoref from '@/components/georef/MapGeoref.vue'
import fab from 'vue-fab'

export default {
    name: 'Home',
    components: {
        TheNavbar,
        BaseLivestream,
        BasePlayer,
        MapGeoref,
        TheTour,
        BaseStopReport,
        fab
    },
    data() {
        return {
            IsGeorefActive: false,
            IsVideoLoading: true,
            CurentLivestream: null,
            IsStreaming: false,
            LivestreamProvider: '',
            BasePlayerSource: '',
            BasePlayerOptions: {
                autoplay: true,
                controls: true,
                preload: true,
                inactivityTimeout: 0,
                liveui: true,
                // fluid: true,
            },
            ForceReRender: 0,
            ForceReRenderFab: 0,
            FabBackgroundColor: '#0f4C81',
            FabActions: [{
                name: 'TurnGeorefOnOff',
                icon: 'location_on',
                tooltip: this.$i18n.t('fab.georefTooltipOn')
            }],
        }
    },
    computed: {
        GetCurrentLivestream() {
            return this.$store.getters['database/GetCurrentLivestream']
        },
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        GetLocaleChange() {
            return this.$i18n.locale
        },
        GetStopReport() {
            let stopReport = this.$store.getters["database/GetStopReport"]
            if (!stopReport || stopReport.timestamp) return this.$store.getters["database/GetStopReport"]
            else return null
        }
    },
    watch: {
        GetCurrentLivestream: {
            async handler(currentLivestream) {
                if (currentLivestream) {
                    this.OpenLivestream(currentLivestream[Object.keys(currentLivestream)])
                }
            }
        },
        GetLocaleChange: {
            handler() {
                this.FabActions[0].tooltip = this.IsGeorefActive ? this.$i18n.t('fab.georefTooltipOff') : this.$i18n.t('fab.georefTooltipOn')
            }
        },
    },
    methods: {
        async OpenLivestream(livestreamObject) {
            this.IsStreaming = false
            this.LivestreamProvider = ''
            this.IsVideoLoading = true
            if (livestreamObject.Provider === 'kinesis') {
                this.LivestreamProvider = livestreamObject.Provider
                const isOnMordorSites = await this.CheckIfKinesisStreamOnMordorSites(livestreamObject)
                if (isOnMordorSites) {
                    const isUserWatchingOnDemand = await this.CheckIfUserWatchingOnDemand(livestreamObject)
                    if (!isUserWatchingOnDemand) this.SetUserWatchingStreamMordorSites(livestreamObject)
                    const ref = firebase.database().ref('mordor-sites/' + livestreamObject.Url + '/status/kinesisVideoService')
                    const listeningTimeout = setTimeout(() => {
                        ref.off()
                        this.LaunchToast('Timeout ao tentar abrir streaming.', 'is-danger')
                        this.CloseStreaming()
                    }, 30000)
                    ref.on('value', (snapshot) => {
                        if (snapshot.val()) {
                            clearTimeout(listeningTimeout)
                            ref.off()
                            this.GetHLSStreaming(livestreamObject)
                        }
                    })

                } else {
                    this.GetHLSStreaming(livestreamObject)
                }
            } else if (livestreamObject.Provider === 'youtube') {
                let id = livestreamObject.Url.split('/').pop()
                this.CurentLivestream = 'https://www.youtube-nocookie.com/embed/' + id + '?autoplay=1&mute=1&controls=0'
                this.IsVideoLoading = false
                this.IsStreaming = true
            } else if (livestreamObject.Provider === 'vimeo') {
                let id = livestreamObject.Url.split('/').pop()
                this.CurentLivestream = 'https://player.vimeo.com/video/' + id
                this.IsVideoLoading = false
                this.IsStreaming = true
            }
        },
        GetHLSStreaming(livestreamObject) {
            const getKinesisVideoUrl = firebase.functions().httpsCallable('getKinesisVideoUrl')
            getKinesisVideoUrl({ streamName: livestreamObject.Url })
                .then((data) => {
                    this.BasePlayerSource = data.data.HLSStreamingSessionURL
                    this.IsStreaming = true
                    this.IsVideoLoading = false
                })
                .catch(() => {
                    this.LaunchToast('Não há streamings pra essa operação.', 'is-warning')
                })
        },
        ShowHideGeoref() {
            this.IsGeorefActive = !this.IsGeorefActive
            if (this.IsGeorefActive) {
                // this.$gtag.event('opened_georeferencing', { 'event_category': 'livestream' })
                this.$store.dispatch('database/bindCurrentGeoJSON', this.GetCurrentUser.activeFlag)
            } else {
                this.$store.dispatch('database/unbindCurrentGeoJSON')
            }
            if (!this.IsStreamingActive) this.ForceReRender += 1 //Hack to rerender component.
            this.FabActions[0].icon = this.IsGeorefActive ? 'location_off' : 'location_on'
            this.FabActions[0].tooltip = this.IsGeorefActive ? this.$i18n.t('fab.georefTooltipOff') : this.$i18n.t('fab.georefTooltipOn')
        },
        SetUserWatchingStreamMordorSites(livestreamObject) {
            return this.$store.dispatch('database/SetOnDatabase', {
                path: 'mordor-sites/' + livestreamObject.Url + '/streaming',
                object: firebase.database.ServerValue.increment(1),
            }).then(() => {
                return this.$store.dispatch('database/SetOnDatabase', {
                    path: 'mordor-sites/' + livestreamObject.Url + '/watching/' + this.GetCurrentUser.uid,
                    object: this.GetCurrentUser.email,
                })
            }).then(() => { this.SetStreamingOnDisconnectHandler(livestreamObject) })
        },
        RemoveUserWatchingStreamMordorSites(livestreamObject) {
            return this.$store.dispatch('database/SetOnDatabase', {
                path: 'mordor-sites/' + livestreamObject.Url + '/streaming',
                object: firebase.database.ServerValue.increment(-1),
            }).then(() => {
                return this.$store.dispatch('database/RemoveFromDatabase', {
                    path: 'mordor-sites/' + livestreamObject.Url + '/watching/' + this.GetCurrentUser.uid
                })
            }).then(() => { this.CancelStreamingOnDisconnectHandler(livestreamObject) })
        },
        SetStreamingOnDisconnectHandler(livestreamObject) {
            this.$store.dispatch('database/SetOnDisconnectHandler', {
                method: 'remove',
                path: 'mordor-sites/' + livestreamObject.Url + '/watching/' + this.GetCurrentUser.uid
            }).then(() => {
                this.$store.dispatch('database/SetOnDisconnectHandler', {
                    method: 'set',
                    path: 'mordor-sites/' + livestreamObject.Url + '/streaming',
                    object: firebase.database.ServerValue.increment(-1)
                })
            })
        },
        CancelStreamingOnDisconnectHandler(livestreamObject) {
            this.$store.dispatch('database/SetOnDisconnectHandler', {
                method: 'cancel',
                path: 'mordor-sites/' + livestreamObject.Url + '/watching/' + this.GetCurrentUser.uid
            }).then(() => {
                this.$store.dispatch('database/SetOnDisconnectHandler', {
                    method: 'cancel',
                    path: 'mordor-sites/' + livestreamObject.Url + '/streaming',
                })
            })
        },
        CheckIfUserWatchingOnDemand(livestreamObject) {
            return this.$store.dispatch('database/GetFromDatabase', {
                path: 'mordor-sites/' + livestreamObject.Url + '/watching',
                returnSnapshotVal: false
            }).then(snapshot => {
                if (!snapshot.exists() || !Object.values(snapshot.val()).includes(this.GetCurrentUser.email)) return false
                else return true
            })
        },
        CheckIfKinesisStreamOnMordorSites(livestreamObject) {
            return this.$store.dispatch('database/GetFromDatabase', {
                path: 'mordor-sites/' + livestreamObject.Url,
                returnSnapshotVal: false
            }).then((snapshot) => { return snapshot.exists() ? true : false })
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({ //Displays toast with given properties below.
                message: message, //Message to display.
                type: type, //Toast's color.
                position: 'is-bottom', //Toast's position.
                duration: 3000 //Toast's duration.
            })
        }
    },
    async created() {
        let livestream = this.$store.getters['database/GetCurrentLivestream']
        if (livestream) {
            this.OpenLivestream(livestream[Object.keys(livestream)])
        }
    },
    async beforeDestroy() {
        this.IsStreaming = false
        this.LivestreamProvider = ''
        let livestream = this.$store.getters['database/GetCurrentLivestream']
        if (livestream[Object.keys(livestream)].Provider === 'kinesis') {
            const isUserWatchingOnDemand = await this.CheckIfUserWatchingOnDemand(livestream[Object.keys(livestream)])
            if (isUserWatchingOnDemand) this.RemoveUserWatchingStreamMordorSites(livestream[Object.keys(livestream)])
        }
    }
}
</script>